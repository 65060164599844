@import "styles/colors";
@import "styles/mixins";

.btn, .btn:hover {
  line-height: 1;
  letter-spacing: pxToRem(0.28);
  margin: 0 pxToRem(30) pxToRem(30) 0;

  a{
    text-decoration: none;
    box-shadow: $default-shadow rgba($color: $algae-green, $alpha: 0.2);
    background-color: $algae-green;
    color: $white;
    border-radius: pxToRem(25);
    padding: pxToRem(13) pxToRem(16) pxToRem(13) pxToRem(32);
    max-width: 218px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}