@import "styles/colors";

.trainer__card{
  border: none;
  border-radius: .375rem;
  padding: 20px;
  font-size: 16px;
  line-height: 18px;
  color: $dark;
  font-weight: normal;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}
.trainer__card__title{
  margin: 20px 0 0;
  padding: 0;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  align-items: center;

  max-width: 200px;
  height: 36px;
  line-height: 18px;

  overflow: hidden;
  display: -webkit-flex;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.trainer__name__card{
  margin: 9px 0 0 0;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $cyan-blue;
}
.text{
  font-size: 14px;
}

.avatar__container{
  width: 210px;
  height: 210px;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0;
}