@import "styles/colors";
@import "styles/mixins";

.product__info {
  position: relative;
  color: $white;
  font-weight: normal;
  letter-spacing: normal;

  h2 {
    position: relative;
    font-size: pxToRem(36);
    font-weight: 600;
    line-height: 1.33;
    color: $white;
    padding-bottom: pxToRem(36);
    margin: 0;

  }

  h2::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: " ";
    width: pxToRem(100);
    height: pxToRem(6);
    border-radius: pxToRem(2);
    background-color: $algae-green;
  }

  p {
    margin: pxToRem(30) 0;
    max-width: pxToRem(680);
    font-size: pxToRem(18);
    line-height: 2;
    color: $bluey-grey;
  }

  a {
    margin-top: pxToRem(0);
    margin-bottom: pxToRem(30);
    margin-right: pxToRem(30);
  }

  .stores {
    margin-top: pxToRem(44);
    width: pxToRem(780);
  }

  .exclamation {
    position: absolute;
    top: 0;
    left: pxToRem(-140);
  }
}

.link, .link:hover{
  text-decoration: none;
}

.btn, .btn:hover {
  line-height: 1;
  letter-spacing: pxToRem(0.28);
  margin: 0 pxToRem(30) pxToRem(30) 0;

  a{
    text-decoration: none;
    box-shadow: $default-shadow rgba($color: $algae-green, $alpha: 0.2);
    background-color: $algae-green;
    color: $white;
    border-radius: pxToRem(25);
    padding: pxToRem(16) pxToRem(30);
  }
}