@import "styles/colors";
.slider__nav {
  text-align: center;
  align-items: center;
  .left__arrow,
  .right__arrow {
    font-size: 20px;
    color: $azure-two;
    display: inline-block;
    vertical-align: middle;
    margin: 1.875rem 1.6rem 0;
  }
}