@import "styles/colors";
@import "styles/mixins";

.card__price {
  font-size: pxToRem(21);
  font-weight: 500;
  color: $dark;
  display: flex;
  align-items: center;
  margin: pxToRem(8) 0 pxToRem(16)
}

.free {
  margin-top: 8px;
  color: $algae-green;
  font-weight: 600;
}

.old {
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: $cyan-blue;
}

.strike {
  text-decoration: line-through
}
.month {
  margin: 8px 0;
  color: $cyan-blue;
  font-size: pxToRem(16);
}