@import "styles/colors";
@import "styles/mixins";

.product__info {
  position: relative;
  color: $dark;
  font-weight: normal;
  letter-spacing: normal;

  h2 {
    position: relative;
    font-size: pxToRem(36);
    font-weight: 600;
    line-height: 1.33;
    color: $dark;
    padding-bottom: pxToRem(36);
    margin: 0;

  }

  h2::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: " ";
    width: pxToRem(100);
    height: pxToRem(6);
    border-radius: pxToRem(2);
    background-color: $darkish-pink;
  }

  p {
    margin: pxToRem(30) 0;
    max-width: pxToRem(680);
    font-size: pxToRem(18);
    line-height: 2;
    color: $cyan-blue;
  }

  a {
    margin-top: pxToRem(0);
    margin-bottom: pxToRem(30);
    margin-right: pxToRem(30);
  }

  .stores {
    margin-top: pxToRem(44);
    width: pxToRem(780);
  }

  .exclamation {
    position: absolute;
    top: 0;
    left: pxToRem(-140);
  }
}

.btn_pink, .btn_pink:hover {
  line-height: 1;
  letter-spacing: pxToRem(0.28);
  margin: 0 pxToRem(30) pxToRem(30) 0;
  word-wrap: break-word;

  @include respond-below(xxs){margin-right: 0 !important}

  a{
    text-decoration: none;
    box-shadow: $default-shadow rgba($color: $darkish-pink, $alpha: 0.2);
    background-color: $darkish-pink;
    color: $white;
    border-radius: pxToRem(25);
    padding: pxToRem(16) pxToRem(30);
    word-wrap: break-word;

    @include respond-below(xxs){margin-right: 0 !important}
  }
}

.workout__level {
  display: flex;
  justify-content: space-between;
  z-index: 10;
  font-size: pxToRem(14);
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  font-weight: 500;
  line-height: 1.29;
  color: $cyan-blue;
}

.days {
  margin: 0 4px 0 8px;
  font-size: 15px;
  line-height: 18px;
}

.img__card {
  object-fit: cover;
}

.content {
  margin-top: 9px;
  padding: 0 10px;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 10px;

  @include multiline-truncate(2);
}

.author{
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
}
.badges{
  min-height: 30px;
  @include custom-gutter(7)
}
.badge {
  background: $ice-blue;
  padding: 6px 0;
  font-size: 14px;
  color: $bluey-grey;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.l__product__card {
  color: $dark;
  min-height: max-content;
  max-width: pxToRem(250);
  margin: 0 pxToRem(16);
  width: 100%;
  font-weight: normal;

  .card__price {
    margin-top: 8px;

    &__month {
      color: $cyan-blue;
      font-size: pxToRem(16);
    }
  }

  &__badges {
    @include custom-gutter(7)
  }

    .icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }

.level {
  color: $cyan-blue;
  font-size: 16px;
  line-height: 1;

  width: 100%;

  img {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
}
.card{
  box-sizing: border-box;
  background-color: $white;
  border-radius: .375rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  overflow: hidden;
  word-wrap: break-word;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon__meet{
  @include icon-fill($azure-three)
}

.link, .link:hover{
  text-decoration: none;
}


.consultation {
  &__filter {
    &__list {
      label {
        margin-left: pxToRem(7);
      }
      .consultation-filter__label_margin {
        margin-bottom: 24px;
      }
    }
  }
}

.duration{
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 18px;
}