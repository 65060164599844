@import 'styles/colors';
@import "styles/mixins";

.paragraph {
  padding: pxToRem(40) 0;
  font-size: pxToRem(18);
  max-height: pxToRem(21);
  line-height: 1.3rem;
}

.search__input__group {
  max-width: pxToRem(600);
  display: flex;
  align-items: center;
  border: solid pxToRem(5) $white;
  border-radius: pxToRem(30);
  box-shadow: $default-shadow $charcoal-grey-5;
  background-color: $white;
  font-weight: normal;
  letter-spacing: normal;
  padding-left: pxToRem(8) !important;
  @include respond-below(xxs){margin-top: 60px}

  input {
    width: 100%;
    min-height: pxToRem(42);
    border: none !important;
    box-sizing: border-box;

    font-size: pxToRem(18);
    line-height: 1.25;
    color: $bluey-grey;
    caret-color: $bluey-grey;
    outline: none;
    padding: pxToRem(1) pxToRem(2) pxToRem(5);

    &::placeholder {
      color: $bluey-grey;
    }
  }

  button {
    text-align: center;
    letter-spacing:.25px;
    font-weight: 600;
    border: solid pxToRem(1) transparent;
    border-radius: pxToRem(20);
    color: $white;
    background-image: linear-gradient(to right, $light-blue, $azure-four);
    font-size: pxToRem(15);
    line-height: 1.2;
    padding: pxToRem(11) pxToRem(30);
  }
}